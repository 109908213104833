import './jquery.jscroll.min.js'
import '../../stylesheets/product_lists/index.scss'
import apiClient from "../../api/apiClient"

$(document).on('turbolinks:before-visit', function() {
  //別ページに遷移する前にスクロール位置を保存する
  sessionStorage['scrollTop_' + location.href] = $(this).scrollTop();
});

$(document).on('turbolinks:load', function() {
  // スクロール位置の設定
  function scrolltop(){
    if(sessionStorage['scrollTop_' + location.href] > $(window).scrollTop()){
      history.scrollRestoration = "manual";
      let targetSession = sessionStorage['scrollTop_' + location.href];
      window.scrollTo(0, targetSession ? targetSession : 0);
    }
  }
  scrolltop();

  // 未投稿商品用のスクロール設定
  function setJscrollForUnposted(){
    if ($('.jscroll-unposted')[0]) {
      $('.jscroll-unposted').jscroll({
        loadingHtml: '<p class="text-center jscroll_text">Loading...</p>',
        contentSelector: '.unposted-content',
        nextSelector: '.unposted_product-lists-pagination li.page-item a[rel~="next"]',
        callback : function(){
          scrolltop();
        }
      });
    }
  }
  setJscrollForUnposted();

  // 投稿済み商品用のスクロール設定
  $('.jscroll').jscroll({
    loadingHtml: '<p class="text-center jscroll_text">Loading...</p>',
    contentSelector: '.product-lists',
    nextSelector: '.product-lists-pagination li.page-item a[rel~="next"]',
    callback : function(){
      setJscrollForUnposted();
      scrolltop();
    }
  });

  if (($('.app__cl')[0] || document.body.clientWidth >= 768) && location.pathname !== '/my_pages/favorites') {
    // ヘッダー固定用 (お気に入りでは無効)
    const appHeader = '#app__header';
    const sortHeader = '#product-lists-sort';
    const appMain = '#js-app-main';
    let startPosition = 0, winScrollTop = 0;
    $(window).on('scroll', function(){
      winScrollTop = $(this).scrollTop();
      if (winScrollTop === 0) {
        $(appMain).css({'padding-top': 0});
        $(appHeader).removeClass('is-fixed');
        $(sortHeader).removeClass('fixed-top');
      } else if (winScrollTop >= startPosition) {
        $(appMain).css({'padding-top': 0});
        $(appHeader).removeClass('is-fixed');
        $(sortHeader).addClass('fixed-top');
      } else {
        $(appMain).css({'padding-top': $(appHeader).height()});
        $(appHeader).addClass('is-fixed');
        $(sortHeader).removeClass('fixed-top');
      }
      startPosition = winScrollTop;
    });
  }

  // 表示順の切り替え (二度押しで無効に)
  let currentRadioValue = $('.js-radio-order:checked').val();
  $(".js-radio-order").on("click", function(){
    if (currentRadioValue === $(this).val()) {
      $(this).prop('checked', false);
    }
    this.form.submit();
  });

  // Snackbar for fadeout
  if ($("#js-snackbar")[0] && location.pathname !== '/my_pages/favorites') {
    const timeToFadeOut = 750;
    $('#js-snackbar-close').click(function(){
      $("#js-snackbar").fadeOut(timeToFadeOut);
      $("#js-snackbar-inner").animate({'margin-top': '-' + $("#js-snackbar").height()}, timeToFadeOut);
    });
    if ($("#js-snackbar-inner").data('name') === 'leve-up-notification') {
      apiClient.delete("/level_notifications");
    }
  }
});
